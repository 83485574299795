import { render, staticRenderFns } from "./OrdersProcessing.vue?vue&type=template&id=9f96a090&scoped=true&"
import script from "./OrdersProcessing.vue?vue&type=script&lang=js&"
export * from "./OrdersProcessing.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9f96a090",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
installComponents(component, {VCard,VCardText})


/* hot reload */
if (module.hot) {
  var api = require("/home/circleci/project/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9f96a090')) {
      api.createRecord('9f96a090', component.options)
    } else {
      api.reload('9f96a090', component.options)
    }
    module.hot.accept("./OrdersProcessing.vue?vue&type=template&id=9f96a090&scoped=true&", function () {
      api.rerender('9f96a090', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/orders/OrdersProcessing.vue"
export default component.exports