<template>
    <div>
        <psi-page-title :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></psi-page-title>
        <!-- Content always starts with a v-card  
        -->
        <v-card class="mt-4">
            <v-card-text>
                <orders-list @filter="filterOrders" @refresh="refreshOrders"></orders-list>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
    name: "orders-processing",
    components: {
        OrdersList: () => import("@components/orders/components/OrdersList"),
    },
    data() {
        return {
            filter: {
                email: "",
            },
            page: {
                title: "Orders",
                icon: "mdi-clipboard-account",
            },
            breadcrumbs: [
                {
                    text: "Messages",
                    disabled: false,
                    to: "/messages/view",
                },
                {
                    text: "Orders",
                    disabled: true,
                },
            ],
        };
    },
    watch: {
        user: {
            immediate: true,
            handler(value) {
                this.filter.email = this.$_.get(value, "email", "");
            },
        },
    },
    computed: {
        ...mapGetters("Order", ["loading", "orders", "searches"]),
        ...mapGetters("User", ["user"]),
    },
    async created() {
        this.getUsers();
        this.getOrders(this.filter.email);
    },
    methods: {
        ...mapActions("Order", ["getOrders"], ["getSearches"]),
        ...mapActions("User", ["getUsers"]),
        filterOrders(email) {
            this.filter.email = email;
            this.getOrders(this.filter.email);
        },
        refreshOrders() {
            this.getOrders(this.filter.email);
        },
    },
};
</script>

<style scoped>
</style>